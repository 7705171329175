/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Anthony Baker
		Date:		14th November 2017
		Author:		Edge Marketing Solutions

===========================================================================*/
/* [Global Variables] - http://sass-lang.com/guide */
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #58595b;
  font-family: "Open Sans", sans-serif;
}

a {
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  color: #005a82;
}

h1 {
  margin: 0 0 40px 0;
  padding: 20px 0 20px 35px;
  position: relative;
  border-left: 7px solid #003552;
  font-weight: 600;
  color: #003552;
  font-size: 3rem;
  line-height: 2.8rem;
}

h1::before {
  content: ' ';
  position: absolute;
  width: 36px;
  height: 7px;
  top: 0;
  left: -1px;
  background-image: url(../images/bracket-top-dark-blue.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

h1::after {
  content: ' ';
  position: absolute;
  width: 18px;
  height: 7px;
  bottom: 0;
  left: -1px;
  background-image: url(../images/bracket-bottom-dark-blue.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

h2 {
  margin: 30px 0;
  font-size: 2.4rem;
  color: #005a82;
}

h3 {
  margin: 30px 0;
  font-size: 1.9rem;
  color: #003552;
}

h4 {
  margin: 30px 0;
  font-size: 1.9rem;
  color: #005a82;
}

h5 {
  margin: 30px 0 10px 0;
  font-size: 1.4rem;
  color: #003552;
  font-weight: 600;
}

h6 {
  margin: 30px 0 10px 0;
  font-size: 1.4rem;
  color: #005a82;
  font-weight: 600;
}

img {
  max-width: 100%;
}

img.svg-responsive {
  width: 100% \9;
}

/* Generated by Glyphter (http://www.glyphter.com) on Tue Sep 06 2016*/
@font-face {
  font-family: 'social icons';
  src: url("../fonts/social-icons.eot");
  src: url("../fonts/social-icons.eot?#iefix") format("embedded-opentype"), url("../fonts/social-icons.woff") format("woff"), url("../fonts/social-icons.ttf") format("truetype"), url("../fonts/social-icons.svg#social-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="social-icon-"]::before, [class*=" social-icon-"]::before {
  display: inline-block;
  font-family: 'social icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.social-icon-square-facebook::before {
  content: '\0041';
}

.social-icon-square-google-plus::before {
  content: '\0042';
}

.social-icon-square-linkedin::before {
  content: '\0043';
}

.social-icon-square-pinterest::before {
  content: '\0044';
}

.social-icon-square-twitter::before {
  content: '\0045';
}

.social-icon-square-youtube::before {
  content: '\0046';
}

.social-icon-circle-facebook::before {
  content: '\0047';
}

.social-icon-circle-google-plus::before {
  content: '\0048';
}

.social-icon-circle-linkedin::before {
  content: '\0049';
}

.social-icon-circle-pinterest::before {
  content: '\004a';
}

.social-icon-circle-twitter::before {
  content: '\004b';
}

.social-icon-circle-youtube::before {
  content: '\004c';
}

.social-icon-rounded-facebook::before {
  content: '\004d';
}

.social-icon-rounded-google-plus::before {
  content: '\004e';
}

.social-icon-rounded-linkedin::before {
  content: '\004f';
}

.social-icon-rounded-pinterest::before {
  content: '\0050';
}

.social-icon-rounded-twitter::before {
  content: '\0051';
}

.social-icon-rounded-youtube::before {
  content: '\0052';
}

[class^="social-icon-circle-"]::before, [class*=" social-icon-circle-"]::before {
  border-radius: 100%;
}

[class^="social-icon-rounded-"]::before, [class*=" social-icon-rounded-"]::before {
  border-radius: 15%;
}

.swiper-container {
  width: 100%;
}

.btn {
  border-radius: 0;
  white-space: normal;
}

.btn-primary {
  background-color: transparent;
  border: 3px solid #003552;
  color: #003552;
}

.btn-primary:hover {
  background-color: #003552;
  border: 3px solid #003552;
  color: white;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .d-xs-none {
    display: none;
  }
  h1 {
    padding: 15px 0 15px 20px;
    font-size: 2.4rem;
  }
  h2 {
    margin: 30px 0;
    font-size: 1.9rem;
  }
  h3 {
    margin: 30px 0;
    font-size: 1.6rem;
  }
  h4 {
    margin: 30px 0;
    font-size: 1.6rem;
  }
  h5 {
    margin: 30px 0 10px 0;
    font-size: 1.2rem;
  }
  h6 {
    margin: 30px 0 10px 0;
    font-size: 1.2rem;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  width: 100%;
  padding: 40px 0;
}

.header .h-logo img {
  max-width: 280px;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .header {
    padding: 30px 0;
    text-align: center;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    padding: 30px 8px;
    text-align: center;
  }
}

/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar-light .navbar-collapse {
  justify-content: flex-end;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding: 0.5rem 1.5rem;
  color: #003552;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #27aae1;
}

.dropdown-item {
  text-align: center;
}

.navbar-light .navbar-toggler {
  width: 100%;
}

/* Large desktop */
@media (min-width: 1200px) {
  .navbar-nav .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-light {
    margin-top: 30px;
  }
  .navbar-light .navbar-collapse {
    justify-content: center;
  }
  .navbar-nav .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-light {
    margin-top: 30px;
    padding: 0;
  }
  .navbar-nav {
    padding-bottom: 15px;
    border: 1px solid rgba(88, 89, 91, 0.2);
    border-top: none;
  }
  .dropdown-menu {
    padding: 0;
    border: none;
    background-color: rgba(88, 89, 91, 0.1);
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.page {
  padding: 50px 0;
}

.feature-item {
  background-color: #005a82;
  color: white;
}

.feature-item .content {
  padding: 20px 20px 30px 20px;
  font-size: 0.8rem;
}

.feature-item a {
  display: block;
  padding: 10px 20px;
  background-color: #27aae1;
  color: white;
}

.feature-item h3 {
  margin: 0 0 20px 0;
  padding: 8px 0 8px 15px;
  position: relative;
  border-left: 4px solid #27aae1;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
}

.feature-item h3::before {
  content: ' ';
  position: absolute;
  width: 21px;
  height: 4px;
  top: 0;
  left: -1px;
  background-image: url(../images/bracket-top-light-blue.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

.feature-item h3::after {
  content: ' ';
  position: absolute;
  width: 10px;
  height: 4px;
  bottom: 0;
  left: -1px;
  background-image: url(../images/bracket-bottom-light-blue.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

.sidebar > h3 {
  margin: 0 0 10px 0;
  font-weight: 600;
  color: #005a82;
}

.sidebar .nav {
  width: 100%;
  display: block;
  margin: 0 0 40px 0;
}

.sidebar .nav > li {
  border-bottom: 1px solid rgba(88, 89, 91, 0.2);
}

.sidebar .nav > li > a {
  padding: 0.7rem 0;
  color: #58595b;
}

.sidebar .nav > li > a:hover {
  color: #27aae1;
}

.subpage-banner {
  width: 100%;
  position: relative;
}

.subpage-banner::after {
  position: absolute;
  content: ' ';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 53, 82, 0.7);
}

.homepage-services-title {
  margin-top: 0;
}

.feature-nav {
  width: 100%;
  padding: 30px 0 45px 0;
  background-color: #003552;
}

.feature-nav .feature-item {
  margin-bottom: 40px;
}

.swiper-container {
  position: relative;
  width: 100%;
  height: 700px;
}

.swiper-slide {
  width: 100%;
  height: 100%;
  position: absolute;
}

.swiper-slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
}

.swiper-slide-image::before {
  position: absolute;
  content: ' ';
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(50% 92.08% 90deg, #003552 0%, rgba(0, 53, 82, 0.6) 100%);
  background: -webkit-linear-gradient(90deg, #003552 0%, rgba(0, 53, 82, 0.6) 100%);
  background: -webkit-gradient(linear, 50% 92.08%, 50% 1.29%, color-stop(0, #003552), color-stop(1, rgba(0, 53, 82, 0.6)));
  background: -o-linear-gradient(90deg, #003552 0%, rgba(0, 53, 82, 0.6) 100%);
  background: -ms-linear-gradient(90deg, #003552 0%, rgba(0, 53, 82, 0.6) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#003552', endColorstr='#003552' ,GradientType=0)";
  background: linear-gradient(0deg, #003552 0%, rgba(0, 53, 82, 0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#003552',endColorstr='#003552' , GradientType=0);
}

.swiper-text {
  position: absolute;
  z-index: 1000;
  width: 600px;
  max-width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.swiper-text .title {
  font-size: 1.6rem;
  font-weight: 300;
  color: #27aae1;
}

.swiper-text .subtitle {
  position: relative;
  font-weight: 600;
  color: white;
  font-size: 3rem;
  line-height: 3.4rem;
}

.swiper-text .wrapper-center {
  padding: 10px 0;
}

.swiper-text .wrapper-left {
  width: 7px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #27aae1;
}

.swiper-text .wrapper-left::before {
  content: ' ';
  position: absolute;
  width: 36px;
  height: 7px;
  top: 0;
  background-image: url(../images/bracket-top-light-blue.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

.swiper-text .wrapper-left::after {
  content: ' ';
  position: absolute;
  width: 18px;
  height: 7px;
  bottom: 0;
  background-image: url(../images/bracket-bottom-light-blue.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

.swiper-text .wrapper-right {
  width: 7px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #27aae1;
}

.swiper-text .wrapper-right::before {
  content: ' ';
  position: absolute;
  width: 36px;
  height: 7px;
  top: 0;
  right: 0;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  background-image: url(../images/bracket-top-light-blue.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

.swiper-text .wrapper-right::after {
  content: ' ';
  position: absolute;
  width: 18px;
  height: 7px;
  bottom: 0;
  right: 0;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  background-image: url(../images/bracket-bottom-light-blue.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .swiper-container {
    height: 600px;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .page {
    padding: 40px 0;
  }
  .swiper-container {
    height: 500px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .page {
    padding: 30px 8px;
  }
  .feature-nav {
    padding: 25px 8px 15px 8px;
  }
  .feature-nav .feature-item {
    margin-bottom: 20px;
  }
  .swiper-container {
    height: 400px;
  }
  .swiper-text .subtitle {
    margin: 0 20px;
    font-size: 2rem;
    line-height: 2.4rem;
  }
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  width: 100%;
  padding: 40px 0;
  border-top: 1px solid #58595b;
  font-size: 0.8rem;
}

.footer .f-logo img {
  max-width: 300px;
}

.footer a {
  color: #58595b;
}

.footer a:hover {
  color: #005a82;
  text-decoration: none;
}

.footer .btn-primary {
  color: #003552;
  font-size: 0.9rem;
}

.footer .btn-primary:hover {
  color: white;
}

.footer .f-actions {
  text-align: right;
}

.footer .btn-social {
  margin-top: 10px;
  display: inline-block;
  font-size: 1.6rem;
}

.social-icon-rounded-linkedin {
  color: #003552;
}

.social-icon-rounded-facebook {
  color: #005a82;
}

.footer .nav {
  width: 100%;
  display: block;
}

.footer .footnotes {
  opacity: 0.7;
  font-size: 0.7rem;
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .footer .f-logo {
    padding-bottom: 40px;
    text-align: center;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    padding: 40px 8px;
    text-align: center;
  }
  .footer .f-actions {
    padding-top: 30px;
    text-align: center;
  }
  .footer .f-nav {
    margin: 30px 0;
  }
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}
